app-condition-question {
  .condition-container {
  }
  .condition-select {
    width: 250px;
    display: inline-block !important;
  }
  .condition-operator {
    margin-left: 10px !important;
    display: inline-block !important;
    .mat-form-field-infix {
      width: 120px;
      max-width: 180px;
      min-width: 50px;
    }
  }

  .condition-selection {
    margin-right: 10px;
    margin-left: 10px !important;
    display: inline-block !important;
    .mat-form-field-infix {
      width: 180px;
    }
  }

  mat-error {
    margin-left: 47px;
    margin-top: -8px;
  }
}
