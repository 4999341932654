.no-content-holder {
  text-align: center;
  position: absolute;
  left: 55%;
  top: 45%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .no-content-text {
    font-size: $no-content-text-size;
    color: $no-content-text-color;
    margin-bottom: 30px;
  }
}
