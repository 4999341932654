@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body, h1, h2, h3, h4, h5, p, section, article {
  font-family: 'Roboto', sans-serif;
  color: $text-color;
}

.capitalize-text {
  text-transform: capitalize;
}

.uppercase-text {
  text-transform: uppercase;
}

.title {
  font-weight: bold;
  color: $create-edit-notification-title;
}
