app-health-plan-steps, .cdk-drag-preview {
app-health-plan-steps, .cdk-drag-preview {
  .mat-column-recurring {
    width: $health-plan-step-column-recurring-width;
    max-width: $health-plan-step-column-recurring-width;
  }

  .mat-column-sort, .mat-column-number {
    width: $health-plan-step-column-sort-width;
    max-width: $health-plan-step-column-sort-width;
  }

  .mat-column-name {
    width: $health-plan-step-column-name-width;
    max-width: $health-plan-step-column-name-width;
  }

  .mat-column-edit {
    width: $health-plan-step-column-edit-width;
    max-width: $health-plan-step-column-edit-width;
    text-align: left;
  }

  .mat-column-persistence {
    width: $health-plan-step-column-persistence-width;
    max-width: $health-plan-step-column-persistence-width;
  }

  .mat-column-silent {
    width: $health-plan-step-column-silent-width;
    max-width: $health-plan-step-column-silent-width;
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: rgba($disabled-btn-background, 0.25);
}
}
