app-login {
  .header {
    padding-top: 90px;

    .main-logo {
      text-align: center;
      margin-bottom: $page-padding;

      img {
        max-width: $sidebar-logo-width;
        display: block;
        margin: 0 auto;
      }

      .logo-title {
        width: auto;
        display: inline-block;
        padding: 2px;
        margin-top: 10px;
        border-radius: 3px;
        line-height: 15px;
        color: $sidebar-title-text;
        background: $sidebar-title-background;
      }
    }
  }

  .form {
    .mat-form-field {
      display: block;
    }
  }
}
