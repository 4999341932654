body {
  background: $site-background;
}

.page-holder {
  margin-left: $sidebar-width;
  padding: $page-padding;
}

.page-nav {
  text-align: right;
  margin-bottom: $page-nav-bottom-margin;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.third-width {
  width: 33%;
}

.inline-block {
  display: inline-block;
}

.float-right {
  float: right;
}

.pointer:hover {
  cursor: pointer;
}
