.mat-table {
  .mat-cell {
    text-transform: capitalize;
    position: relative;
  }

  tr {
    outline: none;
  }
}
