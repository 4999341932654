app-notifications-table {
  .mat-column-id {
    width: 60px;
  }

  .mat-column-actions {
    width: 100px;
  }

  .mat-column-title {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
