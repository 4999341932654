app-default-experience-builder {
  .experience-container {
    padding-top: 16px;
    background: #f4fdfe;
  }
  .recurrence-label {
    margin-left: 47px;
    margin-right: 8px;
  }
}
