app-create-edit-user-behaviour {
  .header-actions {
    position: fixed;
    top: 0px;
    padding-top: 30px;
    padding-bottom: 8px;
    width: calc(100% - 259px);
    background: white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .create-edit-header {
    h1 {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-right: 8px;
    }
  }
  .header-flex {
    display: flex;
  }
  .flex-center {
    align-items: center;
  }
  .create-edit-form {
    padding-top: 30px;
  }
  .not-available-label {
    margin-left: 47px;
    color: $orange;
  }
}
