app-notification-builder-preview {
  #drop-list .module {
    padding: 10px;
    border: 1px dashed $create-edit-notification-builder-module-border;
    position: relative;

    &.text {
      white-space: pre-line;
    }

    .module-product-holder {
      h3 {
        font-size: 16px;
        font-weight: bold;
      }

      img {
        margin-bottom: 20px;
      }

      p {
        font-size: 13px;
        margin-bottom: 0;
      }

      .price {
        font-size: 20px;
        font-weight: bold;
      }

      .left {
        padding-left: 0;
        padding-right: 5px;
      }

      .right {
        padding-right: 0;
        padding-left: 5px;
      }
    }

    img {
      width: 100%;
    }

    hr {
      height: $notification-selected-delimiter-height;
      background: $create-edit-notification-builder-selected-delimiter-color;
      border-top: none;
    }
  }

  .carousel-preview {
    width: 100%;
    position: relative;
    overflow: hidden;

    .carousel-preview-slider {
      display: flex;
      flex-wrap: nowrap;
      left: 0;
      position: relative;
      transition: left 0.2s ease-in;
    }
    .carousel-item {
      width: 100%;
      img {
        padding: 0 20%;
      }
      .carousel-text {
        padding: 24px 10%;
        margin-bottom: 0;
        color: $create-edit-circle-border-color;
        line-height: 19px;
        text-align: center;
      }
    }
  }

  .carousel-dots {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    span {
      width: map-get($carousel-dots, size);
      height: map-get($carousel-dots, size);
      background: $create-edit-notification-btn;
      border-radius: 50%;
      margin: 0 8px;
      &.selected {
        width: map-get($carousel-dots, selected-size);
        height: map-get($carousel-dots, selected-size);
      }
    }
  }

  .video-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
