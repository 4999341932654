.cdk-drag-preview, .cdk-drag-placeholder {
  background: white;
  padding: 10px;
  border: 1px dashed $create-edit-notification-builder-module-border;
  text-align: center;

  img {
    width: 100%;
  }

  hr {
    height: $notification-delimiter-height;
    background: $create-edit-notification-builder-module-border;
  }

  &.title {
    font-weight: bold;
    color: $create-edit-notification-title;
  }

  &.call-to-action {
    button {
      width: 100%;
      background: $create-edit-notification-btn;
      color: $create-edit-notification-background;
      border-radius: 20px;
      text-align: left;
      position: relative;

      mat-icon {
        color: $create-edit-notification-btn;
        background: $main-white;
        border-radius: 50%;
        position: absolute;
        right: 4px;
        top: 50%;
        width: $notification-dismiss-btn-icon-size;
        height: $notification-dismiss-btn-icon-size;
        font-size: $notification-dismiss-btn-icon-size;
        transform: translateY(-50%);
      }
    }
  }
}
