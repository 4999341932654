app-create-edit-health-plan {
  .operation {
    color: $create-edit-operation-color;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bolder;
  }

  .loading-container {
    position: relative;
    top: 0;
    right: 0;
  }
}
