app-segment-conditions-builder {
  display: block;
  margin-left: $create-edit-input-left-offset;
  max-width: $conditions-max-width;

  .group-holder {
    padding: 10px;
    border: 1px solid $conditions-group-border-color;
    margin-top: -1px;
  }

  .button-holder {
    margin-top: 20px;
  }

  .condition-select {
    width: 31.8% !important;
    margin-right: 10px;
    display: inline-block;
  }

  .delete-available {
    .condition-select {
      width: 207px !important;
    }
  }

  .operators-holder {
    display: inline-block;
  }

  .mat-form-field {
    display: inline-block !important;
    max-width: none !important;
    padding-bottom: 0;
    margin-left: 0 !important;
    margin-bottom: -1.3em;
    top: 0;
  }

  .separator-toggle {
    span {
      cursor: pointer;
      padding: 10px;
    }

    &.or-active {
      position: relative;
      top: 25px;
      margin-top: -10px;
      margin-bottom: 15px;

      .or {
        background-color: $conditions-group-border-color;
        border: 1px solid $conditions-group-border-color;
        color: $conditions-group-text-light-color;
      }

      .and {
        background-color: $conditions-group-text-light-background;
        border: 1px solid $conditions-group-border-color;
      }
    }

    &.and-active {
      display: block;
      margin: 20px;

      .and {
        background-color: $conditions-group-border-color;
        border: 1px solid $conditions-group-border-color;
        color: $conditions-group-text-light-color;
      }

      .or {
        background-color: $conditions-group-text-light-background;
        border: 1px solid $conditions-group-border-color;
      }
    }
  }

  .select-container {
    position: relative;
    .mat-spinner {
      position: absolute;
      right: 12px;
      top: 0px;
    }
  }

  .remove-condition {
    color: $deep-grey;
    &:hover {
      cursor: pointer;
      color: darken($deep-grey, 10%);
    }
  }
}
