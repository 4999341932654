.create-edit-header {
  h1 {
    font-size: 24px;
    margin-top: 20px;
    color: $create-edit-title-text-color;
  }

  .float-btn {
    float: right;
  }
  .margin-save-del {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.create-edit-form, .edit-form, .full-width {
  width: 100%;
}

.create-edit-form, .edit-form {
  margin-top: $create-edit-padding-general;

  .full-width {
    margin-bottom: $create-edit-padding-general;
  }

  .sub-label {
    margin-top: 10px;
    margin-left: $create-edit-input-left-offset;
  }

  .checkbox-holder {
    margin-left: $create-edit-input-left-offset;
  }

  .question-number {
    width: $create-edit-circle-size;
    height: $create-edit-circle-size;
    border-radius: 50%;
    border: 2px solid $create-edit-circle-border-color;
    display: inline-block;
    position: relative;
    top: -5px;
    margin-right: 10px;
    text-align: center;
    user-select: none;

    span {
      position: relative;
      top: 4px;
    }
  }

  .blue-container {
    width: $create-edit-form-width;
    margin-left: $create-edit-form-left-margin;
    display: inline-block;
    position: relative;
    padding: 10px;
    background: $create-edit-blue-panel-background;
  }

  .form-margin {
    width: $create-edit-form-width;
    margin-left: $create-edit-form-left-margin;
  }

  .input-meta {
    width: 100%;
    max-width: $create-edit-input-max-width;
    margin-left: $create-edit-input-left-offset;
    display: inline-block;
    text-align: right;
    position: relative;
    top: -20px;
  }

  .mat-form-field {
    display: block;
    max-width: $create-edit-input-max-width;
    margin-left: $create-edit-input-left-offset;
    color: $create-edit-title-text-color;
    position: relative;
    top: -10px;
  }

  .mat-radio-button {
    display: block;
    margin-left: $create-edit-input-left-offset;
    color: $create-edit-title-text-color;
    text-transform: capitalize;
  }

  .inline-input .mat-form-field {
    width: 40px;
  }

  .no-margin-left .mat-form-field {
    margin-left: 0;
  }

  .small-margin-left .mat-form-field {
    margin-left: 10px;
  }

  .inline-message {
    position: relative;
    top: -10px;
  }

  .inline-radios {
    margin-left: 10px;
    display: inline-block;
  }
}

.edit-form {
  margin-left: 0;

  .mat-form-field, .mat-radio-button {
    margin-left: 0;
  }

  .is-deep {
    position: relative;

    .mat-radio-container {
      position: absolute;
      top: 2px;
    }

    .mat-radio-label-content {
      width: 100%;
      margin-left: 20px;
    }
  }
}
