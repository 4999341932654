app-create-edit-link {
  .image-manager-holder {
    padding-right: 0;
  }

  .chosen-img-holder img {
    margin-bottom: 20px;
    margin-top: 10px;
    border: 2px solid $create-edit-circle-border-color;
  }

  .segment-details {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid $create-edit-circle-border-color;

    .segment-info {
      float: left;
      position: relative;
      top: -3px;
    }

    .remove-segment-btn {
      float: right;
      cursor: pointer;
      position: relative;
      top: -3px;
    }
  }
}
