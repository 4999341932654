.mat-column-status {
  width: $status-column-width;
  padding: 0 !important;
  position: relative;
}

app-set-status {
  .status-holder {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
  }

  .status-text {
    padding: 13px 0;
    cursor: pointer;
  }

  .status {
    width: 100%;
    padding: 13px 0;
    position: relative;
    z-index: 999;
    cursor: pointer;
  }

  .status-toggle {
    width: 100%;
    background: $status-toggle-background;
    padding: 10px;
    border: 1px solid $status-toggle-border;
    border-radius: 6px;
    text-align: center;
    position: absolute;
    top: 47px;
    left: 0;
    z-index: 998;
    cursor: pointer;

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background: $status-toggle-background;
      border: 1px solid $status-toggle-border;
      position: absolute;
      top: -10px;
      left: calc(50% - 10px);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      z-index: 998;
    }

    &:after {
      content: '';
      width: 40px;
      height: 20px;
      background: $status-toggle-background;
      position: absolute;
      top: 0;
      left: calc(50% - 20px);
      z-index: 998;
    }
  }
}

.status-draft {
  background-color: $status-draft-background;
  .status-text {
    color: $status-draft-text;
  }
}

.status-in-review {
  background-color: $status-in-review-background;
  .status-text {
    color: $status-in-review-text;
  }
}

.status-ready {
  background-color: $status-ready-background;
  .status-text {
    color: $status-ready-text;
  }
}

.status-approved {
  background-color: $status-approved-background;
  .status-text {
    color: $status-approved-text;
  }
}

.status-published, .status-active {
  background-color: $status-published-background;
  .status-text {
    color: $status-published-text;
  }
}

.status-paused {
  background-color: $status-paused-background;
  .status-text {
    color: $status-paused-text;
  }
}

.blocked {
  cursor: not-allowed;
}
