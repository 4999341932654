$image-manager-override-padding: 20px;

app-create-edit-notifications {
  .warning-text {
    text-align: center;
  }

  .loading-holder {
    vertical-align: top;
    display: inline-block;
    margin: 0 auto;
    width: 33.3%;
  }

  .create-edit-form {
    height: 100vh;
    overflow: scroll;
    padding-top: 5px;
  }

  .inline-dropdown .mat-form-field {
    margin: 0 20px;
  }

  .create-edit-form, .notification-builder, .module-editor {
    width: 33.3%;
    display: inline-block;
  }

  .module-editor {
    height: 100vh;
    overflow: scroll;
    position: fixed;
    vertical-align: top;
    margin-top: $create-edit-padding-general;
    padding: $create-edit-padding-general;
    padding-bottom: 130px;

    h2 {
      text-transform: capitalize;
    }

    .mat-form-field {
      width: 100%;
    }

    img {
      width: auto;
      margin-bottom: 20px;
      max-height: 180px;
    }
  }

  .notification-builder {
    background: $create-edit-notification-builder-background;
    vertical-align: top;
    margin-top: $create-edit-padding-general;
    padding: $create-edit-padding-general;

    .notification-wrapper {
      background: $create-edit-notification-background;
      padding: $create-edit-padding-general 10px;
      border-radius: $create-edit-notification-border-radius;
    }
  }

  .notification-builder, .edit-form {
    iframe {
      width: 100%;
    }
  }

  app-image-manager {
    padding: $image-manager-override-padding 0;
    max-width: 350px;
  }

  .notification-icon-editor app-image-manager {
    .chosen-img {
      max-width: 47px;
    }

    .chosen-img-holder {
      text-align: center;
    }
  }

}
