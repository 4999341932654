app-links-manager-table {
  .mat-column-url, .mat-column-description {
    text-transform: none !important;
  }

  .mat-column-actions, .mat-column-active {
    width: 80px;
  }

  .mat-column-url {
    width: 200px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px !important;

    span {
      text-overflow: ellipsis;
      position: relative;
      top: -3px;
    }

    .mat-icon {
      position: relative;
      top: 3px;
    }
  }

  .mat-column-segments {
    .segment-details-holder {
      cursor: pointer;
    }

    .mat-icon {
      width: 18px;
      font-size: 17px;
      position: relative;
      top: 5px;
    }

    .segment-details {
      position: relative;
      top: 2px;
    }
  }

  .mat-column-icon {
    img {
      max-width: 50px;
    }
  }

  .mat-column-active {
    .mat-icon {
      position: relative;
      top: 3px;
    }
  }
}
