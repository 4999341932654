// Color Vars
$main-white: #FFF;
$main-black: #000;
$pale-grey: #F6F7FB;
$mid-grey: #DBDBDB;
$deep-grey: #9C9C9C;
$dark-grey: #303030;
$warm-grey: #9A8C8D;
$peach: #FFD7CA;
$light-blue: #F4FDFF;
$pale-blue: #3297ce;
$pale-red: #FF8484;
$pale-cyan: #49B9BA;
$bright-green: #25c440;
$bright-red: #ad1c2a;
$deep-orange: #E76E0F;
/*-- Specific Color Vars --*/
$site-background: $main-white;
// Sidebar
$sidebar-color: $pale-grey;
$sidebar-border-color: $mid-grey;
$sidebar-link-background: $main-white;
$sidebar-title-background: $dark-grey;
$sidebar-title-text: $main-white;
// Text
$text-color: $deep-grey;
$title-text-colour: $dark-grey;
// Buttons
$primary-btn-background-color: $peach;
$primary-btn-text-color: $dark-grey;
$primary-stroke-btn-color: $pale-red;
$success-stroke-btn-color: $bright-green;
$accent-stroke-btn-color: $deep-grey;
$radio-button-stroke: $deep-grey;
$back-btn-text-color: $deep-grey;
$success-btn-background: $bright-green;
$success-btn-text: $main-white;
$danger-btn-background: $bright-red;
$danger-btn-text: $main-white;
$disabled-btn-background: $main-black;
// SnackBar
$snackbar-success-background: $bright-green;
$snackbar-error-background: $bright-red;
// Confirmation Modal
$confirmation-modal-title-text: $dark-grey;
// Draft Toggle
$draft-toggle-background: $deep-orange;
$draft-toggle-text: $main-white;
$draft-row-background: $mid-grey;
// No Content
$no-content-text-color: $deep-grey;
// Create Edit
$create-edit-circle-border-color: $deep-grey;
$create-edit-title-text-color: $dark-grey;
$create-edit-operation-color: $pale-blue;
$create-edit-blue-panel-background: $light-blue;
$create-edit-notification-background: $main-white;
$create-edit-notification-title: $pale-blue;
$create-edit-notification-btn: $pale-cyan;
$create-edit-notification-btn-dismiss: $pale-grey;

$create-edit-notification-builder-background: $deep-grey;
$create-edit-notification-builder-module-border: $deep-grey;
$create-edit-notification-builder-carousel-add-icon: #575757;
$create-edit-notification-builder-selected-delimiter-color: #fafafa;
// Conditions
$conditions-group-border-color: $deep-grey;
$conditions-group-text-light-color: $main-white;
$conditions-group-text-light-background: $main-white;
// Status's
$status-toggle-background: $main-white;
$status-toggle-border: $deep-grey;
$status-draft-background: #FBDCC2;
$status-draft-text: $deep-orange;
$status-ready-background: #f7fd72;
$status-ready-text: #9aa201;
$status-approved-background: #E0F0FE;
$status-approved-text: #266FA1;
$status-in-review-background: #EEDEFE;
$status-in-review-text: #AE5AAC;
$status-in-test-background: #FFFBDD;
$status-in-test-text: #ECA933;
$status-paused-background: #E3E3E3;
$status-paused-text: #AFAFAF;
$status-published-background: #E7FFDD;
$status-published-text: #5BCC2E;
// Image Gallery
$image-gallery-chosen-border: $pale-blue;
/*-- Size Vars --*/
$sidebar-width: 200px;
$sidebar-logo-width: 50px;
$page-padding: 30px;
$button-padding: 0 36px;
$page-nav-bottom-margin: $page-padding;
$no-content-text-size: 16px;
$create-edit-circle-size: 35px;
$create-edit-input-left-offset: 47px;
$create-edit-input-max-width: 320px;
$create-edit-padding-general: 30px;
$create-edit-form-width: 80%;
$create-edit-form-left-margin: 47px;
$create-edit-notification-border-radius: 20px;
$create-edit-notification-icon-size: 46px;
$loading-holder-top-offset: 100px;
$conditions-max-width: 700px;
$status-column-width: 120px;
$notification-delimiter-height: 6px;
$notification-dismiss-btn-icon-size: 30px;
$notification-selected-delimiter-height: 2px;
$carousel-dots: (
  size: 13px,
  selected-size: 24.5px
);
$carousel-editing-dots: (
  size: 35px,
  border: 2px
);
/* -- Column Widths -- */
$health-plan-step-column-sort-width: 60px;
$health-plan-step-column-recurring-width: 100px;
$health-plan-step-column-persistence-width: 120px;
$health-plan-step-column-silent-width: 90px;
$health-plan-step-column-name-width: 160px;
$health-plan-step-column-edit-width: 60px;
