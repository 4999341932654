app-main-sidebar {
  .sidebar-holder {
    width: $sidebar-width;
    max-width: $sidebar-width;
    background: $sidebar-color;
    border-right: 1px solid $sidebar-border-color;
    height: 100vh;
    padding-top: 20px;
    position: fixed;
    left: 0;
    top: 0;

    .menu {
      padding-left: 0;

      .section {
        padding-left: 10px;
      }
    }

    .sub-menu {
      padding-left: 0;
      margin-bottom: 20px;

      .link {
        a {
          width: 100%;
          display: block;
          padding: 10px 10px 10px 20px;
          color: $title-text-colour;
          text-decoration: none;

          &:hover {
            background: $sidebar-link-background;
          }
        }
        &.isActive {
          a {
            background: $sidebar-link-background;
          }
        }
      }
    }

    .logout-btn {
      width: 100%;
      display: block;
      padding: 10px 10px 10px 20px;
      color: $title-text-colour;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        background: $sidebar-link-background;
      }
    }

    .main-logo {
      text-align: center;
      margin-bottom: $page-padding;

      img {
        max-width: $sidebar-logo-width;
        display: block;
        margin: 0 auto;
      }

      .logo-title {
        width: auto;
        display: inline-block;
        padding: 2px;
        margin-top: 10px;
        border-radius: 3px;
        line-height: 15px;
        color: $sidebar-title-text;
        background: $sidebar-title-background;
      }
    }
  }
}
