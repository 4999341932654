app-test-health-plan-step-notification-dialog {
  mat-form-field {
    width: 100%;
  }
  .notification-error {
    font-size: 12px;
    margin-bottom: 16px;
  }
  .btn-holder {
    width: 100%;
    button {
      width: calc(50% - 2.5px);
      &:first-of-type {
        margin-right: 5px;
      }
      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        background: darkgrey;
      }
    }
  }
}
