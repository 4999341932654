.confirmation-modal {
  h2 {
    color: $confirmation-modal-title-text;
  }

  .mat-flat-button.success {
    margin-right: 10px;
  }

  .btn-holder {
    text-align: center;
  }
}
