app-notification-builder-options {
  .data-type-options-holder {
    margin-left: $create-edit-form-left-margin;

    .option {
      background: $create-edit-notification-background;
      max-width: $create-edit-input-max-width;
      border: 1px dashed $create-edit-notification-builder-module-border;
      padding: 10px;
      margin-bottom: 10px;
      text-align: center;

      img {
        width: 100%;
      }

      hr {
        height: $notification-delimiter-height;
        background: $create-edit-notification-builder-module-border;
      }

      .carousel-placeholder img {
        width: unset;
        max-height: 180px;
      }
    }
  }

  .product-option {
    .mat-icon {
      position: relative;
      top: 3px;
    }

    span {
      position: relative;
      top: -3px;
    }
  }
}
