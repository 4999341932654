.notification-btn {
  width: 100%;
  background: $create-edit-notification-btn;
  color: $create-edit-notification-background;
  border-radius: 20px !important;
  text-align: left !important;
  position: relative !important;

  mat-icon {
    color: $create-edit-notification-btn;
    background: $main-white;
    border-radius: 50%;
    position: absolute;
    right: 4px;
    top: 50%;
    width: $notification-dismiss-btn-icon-size;
    height: $notification-dismiss-btn-icon-size;
    font-size: $notification-dismiss-btn-icon-size;
    transform: translateY(-50%);
  }

  &-dismiss {
    background: $create-edit-notification-btn-dismiss;
    color: $create-edit-notification-btn;
  }

  &:focus {
    outline: none;
  }
}
