.mat-snack-bar-container {

  ::first-letter {
    text-transform: uppercase;
  }

  &.success {
    background-color: $snackbar-success-background;
  }

  &.error {
    background-color: $snackbar-error-background;
  }
}
