.draft-toggle {
  width: 25px;
  height: 100%;
  cursor: pointer;
  background: $draft-toggle-background;
  color: $draft-toggle-text;
  position: absolute;
  left: 0;
  top: 0;

  .mat-icon {
    position: relative;
    top: 11px;
  }
}

.showing-draft .mat-icon {
  transform: rotate(90deg);
}

.is-draft {
  background: $draft-row-background;
}

.draft-arrow {
  position: absolute;
  left: 9px;
  top: 26%;

  .mat-icon {
    font-size: 18px;
  }
}
