.mat-flat-button.mat-primary {
  background-color: $primary-btn-background-color;
  color: $primary-btn-text-color;
  border-radius: 0;
  padding: $button-padding;
}

.mat-flat-button.success {
  background-color: $success-btn-background;
  color: $success-btn-text;
  border-radius: 0;
  padding: $button-padding;
}

.mat-flat-button.danger {
  background-color: $danger-btn-background;
  color: $danger-btn-text;
  border-radius: 0;
  padding: $button-padding;
}

.mat-stroked-button.mat-primary {
  color: $primary-stroke-btn-color;
  border: 1px solid $primary-stroke-btn-color;
  border-radius: 0;
  box-shadow: none;
  padding: $button-padding;
}
.mat-stroked-button.mat-success {
  color: $success-stroke-btn-color;
  border: 1px solid $success-stroke-btn-color;
  border-radius: 0;
  box-shadow: none;
  padding: $button-padding;
}
.mat-stroked-button.mat-accent {
  color: $accent-stroke-btn-color;
  border: 1px solid $accent-stroke-btn-color;
  border-radius: 0;
  box-shadow: none;
  padding: 0 10px;
}
