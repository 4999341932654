app-notification-home-preview {
  .home-screen-card-wrapper {
    margin: 32px 0 0 0;
    padding: 10px;
    background: white;
    border-radius: 10px;
  }
  .home-screen-card {
    display: flex;
    padding: 20px 32px 14px 28px;
    border: 1.3px solid $create-edit-notification-btn;
    border-radius: 24px;
    position: relative;

    &.single-card {
      margin-bottom: 0;
    }

    .is-editable {
      outline: 1px dashed $bright-red;
      cursor: pointer;
    }

    .home-screen-card-image-holder {
      width: $create-edit-notification-icon-size;
      height: $create-edit-notification-icon-size;
      margin-right: 24px;
    }

    &:first-of-type {
      margin-bottom: 40px;
    }
    .main-icon {
      max-height: $create-edit-notification-icon-size;
      align-self: center;
      margin-right: 24px;
    }
    .pet-icon {
      max-height: $create-edit-notification-icon-size;
      max-width: 42px;
      align-self: center;
      margin-right: 31px;
    }
    .profile {
      position: absolute;
      left: 6px;
      top: 18px;
      max-width: 22px;
      border-radius: 50%;
    }

    .title {
      font-size: 13px;
      color: $create-edit-notification-btn;
      margin-bottom: 6px;
      font-weight: 400;
    }
    .body {
      font-size: 12px;
      line-height: 15px;
      color: #575757;
      margin-bottom: 15px;
    }
    .pet-home-card-image {
      position: relative;
    }
    section {
      mat-icon {
        position: absolute;
        bottom: 12px;
        right: 14px;
        color: $create-edit-notification-btn;
      }
    }
  }
}
