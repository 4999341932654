// Third Party Libs
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '../node_modules/bootstrap/scss/bootstrap-reboot';
@import '../node_modules/bootstrap/scss/bootstrap-grid';
@import '../node_modules/bootstrap/scss/bootstrap-utilities';
@import '../node_modules/bootstrap/scss/type';
// Theme
@import './theme/default/variables';
@import './theme/default/general';
@import './theme/default/typography';
// Components
@import './theme/default/components/back-button';
@import './theme/default/components/no-content';
@import './theme/default/components/create-edit-form';
@import './theme/default/components/loading-holder';
@import './theme/default/components/confimation-modal';
@import './theme/default/components/draft-toggle';
@import './theme/default/components/notification-btn';
// Overrides
@import './theme/default/overrides/material/button';
@import './theme/default/overrides/material/drag-and-drop';
@import './theme/default/overrides/material/table';
@import './theme/default/overrides/material/snackbar';
@import './theme/default/overrides/material/radio-button';
@import './theme/default/overrides/material/checkbox';
// Components
@import './app/auth/components/login/login.component';
@import './app/shared/components/image-manager/image-manager.component';
@import './app/shared/components/main-sidebar/main-sidebar.component';
@import './app/shared/components/set-status/set-status.component';
@import './app/shared/components/notification-builder-preview/notification-builder-preview.component';
@import './app/shared/components/notification-builder-options/notification-builder-options.component';
@import './app/shared/components/notification-builder-edit/notification-builder-edit.component';
@import './app/shared/components/notification-home-preview/notification-home-preview.component';
@import './app/shared/components/condition-question/condition-question.component';
@import './app/segmentation/components/pet-segments-table/pet-segments-table.component';
@import './app/segmentation/components/segment-conditions-builder/segment-conditions-builder.component';
@import './app/engagement/components/create-edit-health-plan/create-edit-health-plan.component';
@import './app/engagement/components/custom-experience-builder/custom-experience-builder.component';
@import './app/engagement/components/health-plan-steps/health-plan-steps.component';
@import './app/engagement/components/health-plans-table/health-plans-table.component';
@import './app/engagement/components/create-edit-notifications/create-edit-notifications.component';
@import './app/engagement/components/test-health-plan-step/test-health-plan-step.component';
@import './app/engagement/components/links-manager-table/links-manager-table.component';
@import './app/engagement/components/create-edit-link/create-edit-link.component';
@import './app/engagement/components/notifications-table/notifications-table.component';
@import './app/engagement/components/create-edit-health-plan-step/create-edit-health-plan-step.component';
@import './app/engagement/components/create-edit-user-behaviour/create-edit-user-behaviour.component';
@import './app/engagement/components/default-experience-builder/default-experience-builder.component';
