app-image-manager {
  padding-right: 30px;
  display: block;

  .image-manager-holder {
    padding-right: 30px;
    display: block;
  }

  .chosen-img {
    width: 100% !important;
    height: auto;
    max-height: none !important;
  }

  .btn-holder {
    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.image-gallery {
  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 200px;
  }

  .gallery-column {
    margin-bottom: 10px;
    text-align: center;
  }

  .page-title {
    position: relative;
    top: 2px;
  }

  .image-holder {
    cursor: pointer;
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(black, 0.6);
      color: #ffffff;
      cursor: not-allowed;
      .overlay-info {
        position: absolute;
        left: calc(50% + 7px);
        top: calc(50% + 7px);
        transform: translate(-50%, -50%);
      }
    }

    &.chosen-image {
      box-sizing: border-box;
      border: 5px solid $image-gallery-chosen-border;
    }
  }

  .gallery-holder {
    padding-left: 0;
    padding-right: 0;

    nav {
      text-align: center;
      margin-bottom: 10px;
      user-select: none;

      .mat-icon-button {
        outline: none;
      }
    }
  }
}
